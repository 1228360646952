<template>
  <landing-page />
</template>

<script>
import LandingPage from "../components/LandingPage/index";
export default {
  components: { LandingPage },
};
</script>

<style></style>

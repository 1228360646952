<template>
  <home-one />
</template>

<script>
import HomeOne from "../components/HomeOne/index";
export default {
  components: { HomeOne },
};
</script>

<style></style>

<template>
  <div v-if="showLoading"></div>
  <div v-else>
    <div>
      <sidebar-main
        :sidebar="sidebar"
        @toggleSidebar="toggleSidebar"
        :menuItems="navs"
      />

      <header-main @toggleSidebar="toggleSidebar" :menuItems="navs" />

      <div id="home" style="margin-top: -80px; padding-top: 80px"></div>
      <main class="main-content">
        <div class="container pt-5">
          <div
            class="row main-content justify-content-center align-items-center mx-0"
          >
            <div
              class="text-center text-lg-start col-12 col-lg-7 pt-5 pb-2 py-lg-0"
            >
              <h1 class="mb-4" style="line-height: 4rem">
                Soluções completas em Informática.
              </h1>
              <h2 class="h3 mb-5 mb-lg-4">
                A tecnologia em pró do seu negócio.
              </h2>

              <div class="mt5">
                <a
                  href="/#more"
                  class="btn btn-grad btn-grad-primary py-3"
                  style="border-radius: 50px; min-width: 260px"
                >
                  Quero saber mais
                </a>
              </div>
            </div>
            <div class="col-12 col-lg-5 text-center" data-aos="zoom-in">
              <img src="@/assets/images/app/banner-people.png" />
            </div>
          </div>
        </div>
      </main>

      <div id="more" style="margin-top: -80px; padding-top: 80px"></div>
      <section class="py-5" style="background-color: #343a40 !important">
        <div class="container text-center py-5">
          <h2 class="h2 text-light mb-4" style="font-weight: 600">
            Descubra como a tecnologia pode impulsionar sua empresa!
          </h2>
        </div>
      </section>

      <div id="services" style="margin-top: -80px; padding-top: 80px"></div>
      <section class="container py-5">
        <h2 class="text-center py-4" style="line-height: 3rem">
          Soluções objetivas e eficientes, para melhorar diretamente e
          indiretamente os resultados do seu negócio!
        </h2>

        <div class="row mx-auto mx-0 py-4">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="card text-center">
              <img
                class="img-fluid"
                src="@/assets/images/app/coding.png"
                style="width: 120px; margin: auto"
              />

              <h3 class="mb-4">Desenvolvimento</h3>
              <p>
                Pensou em desenvolvimento de sites, sites responsivos, SEO,
                adwords, banco de dados? Temos tudo para o seu site.
              </p>
            </div>

            <div class="modal show" tabindex="-1" role="dialog">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Título do modal</h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Fechar"
                      @click="hideModalDevelopment"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>Conteúdo do modal aqui</p>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                      @click="hideModalDevelopment"
                    >
                      Fechar
                    </button>
                    <button type="button" class="btn btn-primary">
                      Salvar mudanças
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="card text-center">
              <img
                class="img-fluid"
                src="@/assets/images/app/web-maintenance.png"
                style="width: 120px; margin: auto"
              />

              <h3 class="mb-4">Infraestrutura</h3>
              <p>
                Cabeamento estruturado, Controladores de domínio, Servidores,
                Backup, Manutenção, Serviços de rede, Computação em nuvem.
              </p>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="card text-center">
              <img
                class="img-fluid"
                src="@/assets/images/app/knowledge.png"
                style="width: 120px; margin: auto"
              />

              <h3 class="mb-4">Segurança</h3>
              <p>
                Firewall, Controle do tráfego de rede, Balanceamento de link,
                Redundância de link, Backup, Roteamento, VPN, Proxy e Muito
                mais.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div id="about" style="margin-top: -80px; padding-top: 80px"></div>
      <section
        class="bg-light py-5"
        data-aos="fade-right"
        data-aos-duration="200"
      >
        <div class="container py-5">
          <div class="row mx-0">
            <div class="col-12 col-lg-6">
              <h2 class="mb-4">
                Controlador de Domínio, é uma tecnologia essencial para qualquer
                empresa.
              </h2>
              <p>
                Um domínio tem a premissa de que um usuário pode ter acesso a
                uma série de recursos de computador com o uso de uma única
                combinação de nome de usuário e senha. Ou seja, qualquer usuário
                pode realizar login em qualquer máquina e acessar os recursos
                cabíveis a ele automaticamente.
              </p>
            </div>

            <div class="text-center col-12 col-lg-6">
              <img
                src="@/assets/images/app/software-engineer-amico.png"
                style="max-width: 320px; margin: auto"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="py-5" data-aos="fade-left" data-aos-duration="200">
        <div class="container py-5">
          <div class="row mx-0">
            <div class="text-center col-12 col-lg-6">
              <img
                src="@/assets/images/app/interaction-design-bro.png"
                style="max-width: 320px; margin: auto"
              />
            </div>

            <div class="col-12 col-lg-6">
              <h2 class="mb-4">
                Suporte técnico para os usuários e computadores.
              </h2>
              <p>
                Ter o rendimento dos funcionários e colaboradores reduzido ou
                prejudicado por falhas, erros ou indisponibilidade de algum
                computador é altamente prejudicial para os negócios,
                principalmente em longo prazo. Conte conosco para reduzir esses
                danos com manutenções preventivas e corretivas de componentes e
                programas.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-light py-5" data-aos="fade-up" data-aos-duration="200">
        <div class="container py-5">
          <div class="row mx-0">
            <div class="col-12 col-lg-6">
              <h2 class="mb-4">
                Possuir uma rotina de Backup é a melhor forma de recuperar
                informação.
              </h2>
              <p>
                Computadores e programas podem falhar de uma hora para outra,
                por diversos motivos, como problemas técnicos, vírus, arquivos
                corrompidos e etc. Impedindo o acesso às informações. Portanto,
                ter uma rotina de backup (Cópia de segurança dos arquivos) é a
                melhor solução nesses casos de emergência.
              </p>
            </div>

            <div class="text-center col-12 col-lg-6">
              <img
                src="@/assets/images/app/open-source-bro.png"
                style="max-width: 420px; margin: auto"
              />
            </div>
          </div>
        </div>
      </section>

      <div id="contact" style="margin-top: -80px; padding-top: 80px"></div>
      <section>
        <div class="container py-5">
          <h2 class="h1 pb-5" style="line-height: 4rem">
            O melhor preço do mercado! Faça um orçamento conosco gratuitamente e
            comprove.
          </h2>

          <div class="row align-items-center mx-0">
            <div class="col-12 col-lg-5">
              <img
                src="@/assets/images/app/startup-life-bro.png"
                style="max-width: 420px; margin: auto"
              />
            </div>

            <div class="col-12 col-lg-7 shadow rounded border p-4">
              <div class="contact-form" v-if="!formSubmit">
                <form class="row" @submit.prevent>
                  <div class="col-12">
                    <input
                      type="text"
                      name="name"
                      v-model="name"
                      placeholder="Nome completo *"
                      required
                    />
                  </div>

                  <div class="col-12">
                    <input
                      type="text"
                      name="company"
                      v-model="company"
                      placeholder="Nome da empresa *"
                      required
                    />
                  </div>

                  <div class="col-12">
                    <input
                      type="email"
                      name="email"
                      v-model="email"
                      placeholder="exemplo@gmail.com *"
                      required
                    />
                  </div>

                  <div class="col-12">
                    <input
                      type="text"
                      name="phone"
                      v-model="phone"
                      placeholder="(61) 99999-9999 *"
                      required
                    />
                  </div>

                  <div class="col-12">
                    <textarea
                      name="message"
                      v-model="message"
                      placeholder="Sua mensagem *"
                      required
                    ></textarea>
                    <small class="form-text text-muted">
                      Entraremos em contato por e-mail ou telefone o mais breve
                      possível
                    </small>
                  </div>

                  <div class="col-12 text-center mt-4">
                    <button
                      type="submit"
                      class="btn btn-info text-light py-3"
                      style="border-radius: 50px; width: 100%; max-width: 280px"
                      @click="handleSubmit"
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
              <div class="text-center py-5" v-else>
                <h4 class="h4">Sua mensagem foi enviada!</h4>
                <p class="p">
                  Entraremos em contato por e-mail ou telefone o mais breve
                  possível
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div style="background-color: #202020">
        <footer class="container py-5" style="background-color: #202020">
          <div class="row mx-0">
            <div class="col-12 col-md-4">
              <img
                class="img-fluid mb-4"
                src="@/assets/images/app/logo.png"
                style="width: 60px"
              />

              <h1 class="text-light h5 mb-4">
                Maguinu Soluções Em Tecnologia da Informação - Ltda
              </h1>

              <p class="text-muted">
                Soluções objetivas e eficientes, para melhorar diretamente e
                indiretamente os resultados do seu negócio.
              </p>
            </div>

            <div class="col-12 col-md-4">
              <div class="px-0 px-md-4 px-lg-5">
                <h1 class="text-light h4 mt-4" style="font-weight: 600">
                  Links Rápidos
                </h1>

                <div class="py-4">
                  <a
                    href="#home"
                    class="text-muted d-block mb-4"
                    style="font-weight: 500"
                  >
                    Início
                  </a>

                  <a
                    href="#services"
                    class="text-muted d-block mb-4"
                    style="font-weight: 500"
                  >
                    Serviços
                  </a>

                  <a
                    href="#about"
                    class="text-muted d-block mb-4"
                    style="font-weight: 500"
                  >
                    Informações
                  </a>

                  <a
                    href="#contact"
                    class="text-muted d-block mb-4"
                    style="font-weight: 500"
                  >
                    Contato
                  </a>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <div class="px-0 px-md-4 px-lg-5">
                <h1 class="text-light h4 mt-4" style="font-weight: 600">
                  Fale Conosco
                </h1>

                <div class="py-4">
                  <a
                    href="mailto:contato@maguinu.com.br"
                    target="_blank"
                    class="text-muted d-block mb-4"
                    style="font-weight: 500"
                  >
                    contato@maguinu.com.br
                  </a>

                  <a
                    href="https://api.whatsapp.com/send?phone=556192265795&text=Olá,%20gostaria%20de%20mais%20informações"
                    target="_blank"
                    class="text-muted d-block mb-4"
                    style="font-weight: 500"
                  >
                    61 9226-5795
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>

    <a
      href="https://api.whatsapp.com/send?phone=556192265795&text=Olá,%20gostaria%20de%20mais%20informações"
      class="float"
      target="_blank"
      id="btn_whatsapp"
    >
      <i class="fab fa-whatsapp my-float"></i>
    </a>
  </div>
</template>

<script>
import HeaderMain from "./HeaderMain.vue";
import SidebarMain from "./SidebarMain.vue";

import axios from "axios";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "LandingPage",
  components: {
    HeaderMain,
    SidebarMain,
  },
  data() {
    return {
      baseURL: "https://app-maguinu.herokuapp.com",

      defaultIcon: require("@/assets/images/app/logo.png"),
      bannerMain: require("@/assets/images/app/5.png"),

      sidebar: false,
      showLoading: false,

      name: "",
      company: "",
      email: "",
      phone: "",
      message: "",

      navs: [],

      formSubmit: false,

      openModalDevelopment: true,
    };
  },
  created() {},
  mounted() {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      delay: 300,
      once: true,
    });
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    showModalDevelopment() {
      this.openModalDevelopment = true;
    },
    hideModalDevelopment() {
      this.openModalDevelopment = false;
    },
    handleSubmit() {
      if (
        this.name != "" &&
        this.company != "" &&
        this.email != "" &&
        this.phone != "" &&
        this.message != ""
      ) {
        this.formSubmit = true;

        axios.post(`${this.baseURL}/submit`, {
          name: this.name,
          company: this.company,
          email: this.email,
          phone: this.phone,
          message: this.message,
        });
      }
    },
  },
};
</script>

<style>
.main-content {
  min-height: 100vh;
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.main {
  padding-top: 80px;
  min-height: 100vh;
}

.card {
  min-height: 460px;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  border-radius: 0.25rem;
}

.card:hover {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.icon-services {
  margin: auto;
  fill: transparent;
  stroke-width: 10;
  stroke: #207be9;
}

.btn-grad {
  transition: 0.8s;
  background-size: 200% auto;
  color: white;
}

.btn-grad:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.btn-grad-primary {
  background-image: linear-gradient(
    to right,
    #0dcaf0 0%,
    #098da8 51%,
    #0dcaf0 100%
  );
  color: white !important;
}

.btn-grad-secondary {
  background-image: linear-gradient(
    to right,
    #385284 0%,
    #5d78a8 51%,
    #385284 100%
  );
}

.btn-grad-danger {
  background-image: linear-gradient(
    to right,
    #e52d27 0%,
    #b31217 51%,
    #e52d27 100%
  );
}

.btn-grad-orange {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #f09819 51%,
    #ff512f 100%
  );
}

.btn.circle {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 40px;
  background-color: #25d366;
  color: #fff !important;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
</style>
